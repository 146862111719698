import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import PageWrapper from "../components/PageWrapper";

import imgPartner from "../assets/image/png/surfboard_rack.png";

const StyledContainer = styled(Container)`
  height: 100vh;
`;
const DevelopmentPage = () => {
  return (
    <>
      <PageWrapper headerConfig={{ theme: "dark" }}>
        <div className="inner-banner pt-29 dark-mode-texts bg-blackish-blue">
          <StyledContainer>
            <Row className="justify-content-center align-items-center">
              <Col lg="7" sm="10" className="pb-lg-12 pr-lg-12">
                <h3>We are looking for partners.</h3>
                <p>
                  Due to the extremely varied possibilities for application of our
                  technology, we are looking for a well established partner in
                  each sector of the marine and watersports industries.
                </p>
                <p>
                  Engineering consultation and design verification may be
                  provided to each of our partners if desired, along with material samples
                  and sources for direct material suppliers.
                </p>
                <p>
                  Our goal is to find partners committed to developing the
                  highest quality products.
                </p>
                <p>
                  If you would be interested in utilizing or testing our technology, please
                  contact us.
                </p>
              </Col>
              <Col lg="5" sm="10" className="mt-xs-10 mt-lg-0">
                <div>
                  <img src={imgPartner} className="w-100 rounded-5" />
                </div>
              </Col>
            </Row>
          </StyledContainer>
        </div>
      </PageWrapper>
    </>
  );
};

export default DevelopmentPage;
